export const siteLogo = {
    logo: "assets/images/logo_comeia_academy3.png",  // Add your site logo here
    alt: "Comeia Academy 2023"  // Add your site name here
};
export const socialLink = {
    facebook: "https://www.facebook.com/comeialabs/",
    instagram: "https://www.instagram.com/comeialabs/",
    twitter: "",
    linkedin: "https://www.linkedin.com/company/comeialabs/",
    youtube: "https://www.youtube.com/@COMEIALabs",
    whatsapp: ""
};
export const mailchimpLink = {
    link: "https://gmail.us18.list-manage.com/subscribe/post?u=75c566c5187dae4ce350394ad&amp;id=9c807c8eb5&amp;f_id=00c127e7f0" // Add your mailchimp list link here
};
export const contactFormAction = {
    link: (process.env.REACT_APP_CONTACTFORM)  // Add for link here from https://formspree.io
}
export const contactMap = {
    link: (process.env.REACT_APP_CONTACTMAP) // Add google map link from here: https://www.embedgooglemap.net/
}
