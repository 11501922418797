import React from "react";
import Achieve from "../achieve";
import Achievement from "../achievement";
import AuthorV2 from "../authorv2";
import Authorv4 from "../authorv4";
// import Booksv2 from "../booksv2";
import Chapter from "../chapter";
import ChapterPreview from "../chapter-preview";
import Contact from "../contact";
import Ctav4 from "../ctav4";
import Ctav5 from "../ctav5";
import FunFacts from "../fun-facts";
import Footer from "../global/footer";
import Header from "../global/header";
import Hero from "../hero";
// import Pricing from "../pricing";
// import Pricing2 from "../pricing2";
import Testimonial from "../testimonial";
import Eventv2 from "../eventv2";
import Achievev2 from "../achievev2";

const Version01 = ({header, footer}) => {
  const {menu} = footer;

  return (
    <>
     <Header header={header} />
      <Hero isBg="yes" /> {/* HOME */}
      {/* <Eventv2 isBg=""/> {/* CRONOGRAMA */}
      <Authorv4 isBg=""/> {/* ACADEMY 2023 */}
      <Achieve isBg="yes" /> {/* PERFIL DO ALUNO */}
      <FunFacts isBg=""/> {/* O CURSO */}
      <Chapter isBg="yes" /> {/* MÓDULOS */}
      <Achievement isBg="" /> {/* PARCEIROS */}
      <AuthorV2 isBg="yes" /> {/* O COMEIA */}
      <ChapterPreview isBg="" /> {/* EQUIPE ACADEMY */}
      <Testimonial isBg="yes" /> {/* DEPOIMENTOS */}
      <Ctav5 isBg=""/> {/* ESTUDE AGORA */}
      {/* <Pricing isBg="" /> INVESTIMENTO */}
      {/* <Pricing2 isBg="yes" /> BOLSA COMEIA */}
      <Achievev2 isBg="yes"/> {/* COMO FUNCIONA */}
      {/* <Booksv2 isBg="yes" /> */}
      <Contact isBg="" /> {/* INSCREVA-SE */}
      <Ctav4 isBg="yes" /> {/* ENCERRAMENTO */}
      <Footer  isBg="" menu={menu} /> {/* AINDA TEM */}
    </>
  );
}

export default Version01;
