/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import data from '../data/contact.json';
import { db } from '../firebase/firebaseConfig';
import { useForm } from 'react-hook-form';

function phone(e) {
  e.currentTarget.maxLength = 15;

  let { value } = e.currentTarget;

  if (e.key === 'Backspace') {
    return e;
  }

  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})/, '($1) ');
  value = value.replace(/(\d{5})/, '$1-');

  e.currentTarget.value = value;

  return e;
}

const Contact = ({ isBg }) => {
  const { contact } = data;
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    document.getElementById('email-loading2').style.display = 'block';

    db.collection('leads')
      .add({
        name: data.name,
        email: data.email,
        whatsapp: data.whatsapp,
        time: new Date(),
      })
      .then(() => {
        // var link = document.createElement('a');
        // link.href = "assets/Comeia Academy 2023.1 - Edital.pdf";
        // link.download = 'Comeia Academy 2023.1 - Edital.pdf';
        // link.dispatchEvent(new MouseEvent('click'));
        document.getElementById('email-loading2').style.display = 'none';
        document.getElementById('email-success2').style.display = 'block';
        setTimeout(() => {
          document.getElementById('email-success2').style.display = 'none';
        }, 3000);
        document.getElementById('contact-name2').value = '';
        document.getElementById('contact-email2').value = '';
        document.getElementById('contact-whatsapp2').value = '';
      })
      .catch(() => {
        document.getElementById('email-failed2').style.display = 'block';
      });
  };

  return (
    // <!-- ========== Contact section start ========== -->
    <section
      id="contact"
      className={`p-80px-tb ${isBg === 'yes' ? 'bg-one' : ''}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{contact.subtitle}</span>
              <h2 className="display-6">{contact.title}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <!--  contact form area start --> */}
          <div
            className="col-lg-8 col-md-6 col-sm-12 m-25px-b"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="contact-form-box">
              <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="message col">
                  <p
                    id="email-loading2"
                    className="email-loading alert alert-warning"
                  >
                    <img src="assets/images/loading.gif" alt="" />
                    &nbsp;&nbsp;&nbsp;Registrando interesse...
                  </p>
                  <p id="email-success2" className="email-success alert alert-success">
                    <i className="icofont-check-circled"></i> Interesse registrado com sucesso.
                  </p>
                  <p
                    id="email-failed"
                    className="email-failed alert alert-danger"
                  >
                    <i className="icofont-close-circled"></i> Algo deu errado!
                    Favor entrar em contato conosco por nossas redes sociais.
                  </p>
                </div>
                <div className="mb13">
                  <input
                    name="name"
                    className="contact-name"
                    id="contact-name2"
                    type="text"
                    placeholder="Seu Nome"
                    // onChange={updateInput}
                    // value={formData.name || ''}
                    {...register('name', { required: true })}
                    required
                  />
                </div>
                <div className="mb13">
                  <input
                    name="email"
                    className="contact-email"
                    id="contact-email2"
                    type="email"
                    placeholder="Seu E-mail"
                    // onChange={updateInput}
                    // value={formData.email || ''}
                    {...register('email', { required: true })}
                    required
                  />
                </div>
                <div className="mb13">
                  <input
                    name="whatsapp"
                    className="contact-subject"
                    id="contact-whatsapp2"
                    type="text"
                    placeholder="Seu Whatsapp"
                    // onChange={updateInput}
                    // value={formData.whatsapp || ''}
                    {...register('whatsapp', { required: true })}
                    onKeyUp={(e) => phone(e)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="button button__primary align-items-center"
                  // onClick={submit}
                >
                  <span>{contact.btnText}</span>
                </button>
              </form>
            </div>
          </div>
          {/* <!--  contact form area end --> */}
          {/* <!-- contact information start --> */}
          <div
            className="col-lg-4 col-md-6 col-sm-12 m-25px-b"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="author__image">
              <img
                className="img-fluid img"
                src={contact.image}
                alt="Main Video"
              />
            </div>
          </div>
          {/* <!-- contact information end --> */}
        </div>
      </div>
    </section>
    // <!-- ========== Contact section end ========== -->
  );
};

export default Contact;
