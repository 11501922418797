import React from 'react';
import CountUp from 'react-countup';
import data from '../data/fun-fact.json';

const FunFacts = ({ isBg }) => {
  const { funFacts } = data;
  return (
    // <!-- ========== Fun facts section start ========== -->
    <section
      className={`section-padding counters ${isBg === 'yes' ? 'bg-one' : ''}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{funFacts.subtitle}</span>
              <h2 className="display-6">{funFacts.title}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="counters__stats m-0 p-0 d-flex flex-wrap align-items-center justify-content-center">
              <li
                key={0}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={(0 + 2) * 50}
              >
                <div className="counters__stats-box h-100 translateEffect1">
                  <div className="counters__stats-icon">
                    <img
                      className="img-fluid"
                      src={data.icon}
                      alt="icon"
                      width="100"
                      height="100"
                    />
                  </div>
                  <div className="counters__stats-box__number">
                    <CountUp end={6} enableScrollSpy="true" />
                    <span className='fs-4'> meses</span>
                  </div>
                  <h5>de duração do curso</h5>
                </div>
              </li>
              <li
                key={1}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={(1 + 2) * 50}
              >
                <div className="counters__stats-box h-100 translateEffect1">
                  <div className="counters__stats-icon">
                    <img
                      className="img-fluid"
                      src={data.icon}
                      alt="icon"
                      width="100"
                      height="100"
                    />
                  </div>
                  <div className="counters__stats-box__number">
                    <CountUp end={500} enableScrollSpy="true" />
                    <span className='fs-4'>h</span>
                    <span className='fs-1'>+</span>
                  </div>
                  <h5>de aulas + atividades</h5>
                </div>
              </li>
              <li
                key={2}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={(2 + 2) * 50}
              >
                <div className="counters__stats-box h-100 translateEffect1">
                  <div className="counters__stats-icon">
                    <img
                      className="img-fluid"
                      src={data.icon}
                      alt="icon"
                      width="100"
                      height="100"
                    />
                  </div>
                  <div className="counters__stats-box__number">
                    <CountUp end={200} enableScrollSpy="true" />
                    <span className='fs-4'>h</span>
                    <span className='fs-1'>+</span>
                  </div>
                  <h5>de aulas gravadas</h5>
                </div>
              </li>
              <li
                key={3}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={(3 + 2) * 50}
              >
                <div className="counters__stats-box h-100 translateEffect1">
                  <div className="counters__stats-icon">
                    <img
                      className="img-fluid"
                      src={data.icon}
                      alt="icon"
                      width="100"
                      height="100"
                    />
                  </div>
                  <div className="counters__stats-box__number">
                    <CountUp end={100} enableScrollSpy="true" />
                    <span className='fs-4'>h</span>
                    <span className='fs-1'>+</span>
                  </div>
                  <h5>de encontros ao vivo</h5>
                </div>
              </li>
              <li
                key={4}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={(4 + 2) * 50}
              >
                <div className="counters__stats-box h-100 translateEffect1">
                  <div className="counters__stats-icon">
                    <img
                      className="img-fluid"
                      src={data.icon}
                      alt="icon"
                      width="100"
                      height="100"
                    />
                  </div>
                  <div className="counters__stats-box__number">
                    <CountUp end={100} enableScrollSpy="true" />%
                  </div>
                  <h5>concluintes contratados</h5>
                  {/* <span className='fs-4'>*</span> */}
                </div>
              </li>
            </ul>
            <p className="text-center">
              * A contratação depende do desenvolvimento e da entrega do aluno, bem como da demanda de mercado.
            </p>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Fun facts section end ========== -->
  );
};

export default FunFacts;
