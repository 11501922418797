import React, { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import data from '../data/hero.json';
import { mailchimpLink } from '../global';
import { FiPlay } from 'react-icons/fi';
import { Axios, db } from '../firebase/firebaseConfig';
import { useForm } from 'react-hook-form';

//VALIDATE WHATSAPP
function phone(e) {
  e.currentTarget.maxLength = 15;

  let { value } = e.currentTarget;

  if (e.key === 'Backspace') {
    return e;
  }

  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})/, '($1) ');
  value = value.replace(/(\d{5})/, '$1-');

  e.currentTarget.value = value;

  return e;
}

//SUBSCRIBE FORM
function SubscribeForm({ status, message, onValidated }) {
  const [formData, setFormData] = useState({});
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    document.getElementById('email-loading').style.display = 'block';

    db.collection('leads')
      .add({
        name: data.name,
        email: data.email,
        whatsapp: data.whatsapp,
        time: new Date(),
      })
      .then(() => {
        // var link = document.createElement('a');
        // link.href = 'assets/Comeia Academy 2023.1 - Edital.pdf';
        // link.download = 'comeia-academy-2023.1-edital.pdf';
        // link.dispatchEvent(new MouseEvent('click'));
        document.getElementById('email-loading').style.display = 'none';
        document.getElementById('email-success').style.display = 'block';
        setTimeout(() => {
          document.getElementById('email-success').style.display = 'none';
        }, 3000);
        document.getElementById('contact-name').value = '';
        document.getElementById('contact-email').value = '';
        document.getElementById('contact-whatsapp').value = '';
      })
      .catch(() => {
        document.getElementById('email-failed').style.display = 'block';
      });
  };
  let email;

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submit = (e) => {
    e.preventDefault();
    email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
      });

    // sendEmail();

    db.collection('leads').add({
      name: formData.name,
      email: formData.email,
      whatsapp: formData.whatsapp,
      time: new Date(),
    });

    setFormData({
      name: '',
      email: '',
      whatsapp: '',
    });

    // var link = document.createElement('a');
    // link.href = "assets/Comeia Academy 2023.1 - Edital.pdf";
    // link.download = 'comeia-academy-2023.1-edital.pdf';
    // link.dispatchEvent(new MouseEvent('click'));
  };

  // SEND EMAIL
  const sendEmail = () => {
    Axios.post(
      'https://us-central1-landing-academy.cloudfunctions.net/submit',
      formData
    )
      .then((res) => {
        db.collection('leads').add({
          name: formData.name,
          email: formData.email,
          whatsapp: formData.whatsapp,
          time: new Date(),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="subscribe-form-box px-0">
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <div className="message col">
          <p id="email-loading" className="email-loading alert alert-warning">
            <img src="assets/images/loading.gif" alt="" />
            &nbsp;&nbsp;&nbsp;Registrando interesse...
          </p>
          <p id="email-success" className="email-success alert alert-success">
            <i className="icofont-check-circled"></i> Interesse registrado com sucesso.
          </p>
          <p id="email-failed" className="email-failed alert alert-danger">
            <i className="icofont-close-circled"></i> Algo deu errado! Favor
            entrar em contato conosco por nossas redes sociais.
          </p>
        </div>
        <div className="mb13">
          <input
            name="name"
            className="contact-name"
            id="contact-name"
            type="text"
            placeholder="Seu Nome"
            {...register('name', { required: true })}
            required
          />
        </div>
        <div className="mb13">
          <input
            name="email"
            className="contact-email"
            id="contact-email"
            type="email"
            placeholder="Seu E-mail"
            {...register('email', { required: true })}
            required
          />
        </div>
        <div className="mb13">
          <input
            name="whatsapp"
            className="contact-whatsapp"
            id="contact-whatsapp"
            type="text"
            placeholder="Seu Whatsapp"
            {...register('whatsapp', { required: true })}
            onKeyUp={(e) => phone(e)}
            required
          />
        </div>
        <button
          type="submit"
          className="button button__primary align-items-center"
        >
          <span>Quero saber!</span>
        </button>

        <div className="message col m-10px-t hero__subscribe__form__message">
          {status === 'sending' && (
            <div className=" alert alert-warning">sending...</div>
          )}
          {status === 'error' && (
            <div
              className="alert alert-danger"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
        {status === 'success' && (
          <div
            className="alert alert-success hero__subscribe__form__success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </form>
    </div>
  );
}

const Hero = ({ isBg }) => {
  const { herov1 } = data;
  return (
    // <!-- ========== Hero section start ========== -->
    <section
      id="hero"
      className={`hero hero__padding overflow-hidden position-relative ${
        isBg === 'yes' ? 'bg-one' : ''
      }`}
    >
      <div className="hexagon x1"></div>
      <div className="hexagon x2"></div>
      <div className="hexagon x3"></div>
      <div className="hexagon x4"></div>
      <div className="hexagon x5"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 m-0 px-b md-m-30px-b px-5">
            <div className="hero__content position-relative">
              <div className="badge-text2 mb-2 text-uppercase">
                {herov1.subtitle}
              </div>
              <h1 className="display-4 mb-2 font-weight-0">{herov1.title}</h1>
              <div className="text-muted fs-5">{herov1.description}</div>
              <MailchimpSubscribe
                url={mailchimpLink.link}
                render={({ subscribe, status, message }) => (
                  <SubscribeForm
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                  />
                )}
              />
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="author__image">
              <img
                className="img-fluid img"
                src={herov1.image}
                alt="Main Video"
              />
              <a href={herov1.video} className="glightbox3 video-btn">
                <FiPlay />
              </a>
              <div className="promo-video">
                <div className="waves-block">
                  <div className="waves wave-1"></div>
                  <div className="waves wave-2"></div>
                  <div className="waves wave-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    // <!-- ========== Hero section start ========== -->
  );
};

export default Hero;
