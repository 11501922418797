import React from 'react';
import achievementData from '../data/achievements.json';
const Achievement = ({ isBg }) => {
  const { achievements } = achievementData;

  return (
    <section
      id="achievements"
      className={`section-padding achievement ${
        isBg === 'yes' ? 'bg-one' : ''
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{achievements.title}</span>
              <h2 className="display-6">{achievements.subtitle}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="author__image2">
          <div className="row row-cols-1 row-cols-md-2">
            <img
              className="img-fluid img"
              src="/assets/images/parceiros.png"
              alt="Parceiros"
            />
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Achievement section end ========== -->
  );
};

export default Achievement;
