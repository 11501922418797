import React from 'react';
import { Link } from 'react-router-dom';
import data from '../data/events.json';

const Eventv2 = ({ isBg }) => {
  const { events } = data;
  return (
    <section
      id="events"
      className={`section-padding event ${isBg === 'yes' ? 'bg-one' : ''}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{events.title}</span>
              <h2 className="display-6">{events.subtitle}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
          <div
            key={data.id}
            className="col m-30px-b"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={2 * 50}
          >
            <div className="card h-100 translateEffect1">
              <div className="card-body">
                <div className="date">
                  <p>
                    28<span>DEZ</span>
                    <span> a </span>
                    08<span>JAN</span>
                  </p>
                </div>
                <h4 className="mb-3">Inscrições</h4>
                <p>
                  Inscrições encerradas! Preencha o formulário acima para ficar por dentro das próximas turmas ;)
                </p>
              </div>
              {/* <Link to="/single-event" className="link">
                  {data.btn}
                  <i className="icofont-rounded-right"></i>
                </Link> */}
            </div>
          </div>
          <div
            key={data.id}
            className="col m-30px-b"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={2 * 50}
          >
            <div className="card h-100 translateEffect1">
              <div className="card-body">
                <div className="date">
                  <p>
                    11<span>JAN</span>
                  </p>
                </div>
                <h4 className="mb-3">Selecionados prova</h4>
                <p>
                  A lista dos selecionados para realizar a Prova de Conhecimentos já foi divulgada nas nossas redes sociais =)
                </p>
              </div>
              {/* <Link to="/single-event" className="link">
                  {data.btn}
                  <i className="icofont-rounded-right"></i>
                </Link> */}
            </div>
          </div>
          <div
            key={data.id}
            className="col m-30px-b"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={2 * 50}
          >
            <div className="card h-100 translateEffect1">
              <div className="card-body">
                <div className="date">
                  <p>
                    14<span>JAN</span>
                  </p>
                </div>
                <h4 className="mb-3">Prova de conhecimentos</h4>
                <p>
                  Realizaremos um simples teste de conhecimentos em matemática básica e lógica, apenas para avaliar os conhecimentos mínimos necessários para a área de programação.
                </p>
              </div>
              {/* <Link to="/single-event" className="link">
                  {data.btn}
                  <i className="icofont-rounded-right"></i>
                </Link> */}
            </div>
          </div>
          <div
            key={data.id}
            className="col m-30px-b"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={2 * 50}
          >
            <div className="card h-100 translateEffect1">
              <div className="card-body">
                <div className="date">
                  <p>
                    18<span>JAN</span>
                  </p>
                </div>
                <h4 className="mb-3">Selecionados entrevista</h4>
                <p>
                  Por questões logísticas, vamos selecionar 80 candidatos a partir da provinha de conhecimentos, os quais participarão de uma pequena entrevista.
                </p>
              </div>
              {/* <Link to="/single-event" className="link">
                  {data.btn}
                  <i className="icofont-rounded-right"></i>
                </Link> */}
            </div>
          </div>
          <div
            key={data.id}
            className="col m-30px-b"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={2 * 50}
          >
            <div className="card h-100 translateEffect1">
              <div className="card-body">
                <div className="date">
                  <p>
                    23<span>JAN</span>
                    <span> a </span>
                    27<span>JAN</span>
                  </p>
                </div>
                <h4 className="mb-3">Entrevistas</h4>
                <p>
                  Entre esses dias, marcaremos um horário para uma rápida conversa, em que teremos uma melhor noção de sua aptidão para a realização do curso.
                </p>
              </div>
              {/* <Link to="/single-event" className="link">
                  {data.btn}
                  <i className="icofont-rounded-right"></i>
                </Link> */}
            </div>
          </div>
          <div
            key={data.id}
            className="col m-30px-b"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={2 * 50}
          >
            <div className="card h-100 translateEffect1">
              <div className="card-body">
                <div className="date">
                  <p>
                    01<span>FEV</span>
                  </p>
                </div>
                <h4 className="mb-3">Resultado final</h4>
                <p>
                  Enfim, o grande dia!! Se seu nome estiver na lista, pode comemorar e preparar suas malas para essa jornada. Se não estiver, não fique triste, pois em breve abriremos novas turmas ;)
                </p>
              </div>
              {/* <Link to="/single-event" className="link">
                  {data.btn}
                  <i className="icofont-rounded-right"></i>
                </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Eventv2;
